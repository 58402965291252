var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "files": "Loading..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "m-subheader"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-auto"
  }, [_c('h3', {
    staticClass: "m-subheader__title m-subheader__title--separator"
  }, [_vm._v(" List " + _vm._s(_vm.$route.meta.label) + " "), _c('b', {
    staticStyle: {
      "color": "blue"
    }
  }, [_vm._v(_vm._s(_vm.modeForm))])]), _c('p', {
    staticClass: "m-nav m-nav--inline"
  })]), _c('div', {
    staticClass: "d-flex align-items-right"
  }, [this.modeForm == 'Verify' ? _c('button', {
    staticClass: "btn m-btn--pill m-btn--air btn-success",
    staticStyle: {
      "margin": "18px",
      "width": "85%"
    },
    on: {
      "click": _vm.openDisplay
    }
  }, [_vm._v(" /display/ " + _vm._s(this.$store.getters['customer/customer'].token_trx) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "m-content"
  }, [_c('div', {
    staticClass: "m-portlet m-portlet--mobile"
  }, [_c('div', {
    staticClass: "m-portlet__body"
  }, [_c('div', {
    staticClass: "m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
  }, [_c('div', {
    staticClass: "row align-items-left"
  }, [_c('div', {
    staticClass: "col-xl-12 order-2 order-xl-1"
  }, [_c('div', {
    staticClass: "form-group m-form__group row align-items-center"
  }, _vm._l(_vm.filters, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "col-md-4"
    }, [item.field == 'type' ? _c('div', {
      staticClass: "m-input-icon m-input-icon--left"
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": item.label
      },
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }, _vm.onSearch]
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "selected": "selected"
      }
    }, [_vm._v("Tipe")]), _c('option', {
      attrs: {
        "value": "wa",
        "selected": "selected"
      }
    }, [_vm._v("Whatsapp")]), _c('option', {
      attrs: {
        "value": "email",
        "selected": "selected"
      }
    }, [_vm._v("Email")])])]) : item.field != 'btn' ? _c('div', {
      staticClass: "m-input-icon m-input-icon--left"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": item.label
      },
      domProps: {
        "value": _vm.options[item.field]
      },
      on: {
        "change": _vm.onSearch,
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(_vm.options, item.field, $event.target.value);
        }
      }
    }), _vm._m(0, true)]) : _vm._e()]);
  }), 0)])])]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', [(_vm.$store.getters['customer/customer'].role_id = 1) ? _c('button', {
          staticClass: "btn btn-danger",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteMaster(props.row.id);
            }
          }
        }, [_vm._v("Delete")]) : _vm._e()]) : props.column.field == 'hp' ? _c('span', [_c('a', {
          attrs: {
            "href": _vm.linkWA(props.row.hp),
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row.hp))])]) : props.column.field == 'name' ? _c('span', [_c('a', {
          attrs: {
            "href": props.row.name,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row.name))])]) : props.column.field == 'ticket_status_id' ? _c('span', [props.row.ticket_status_id == 1 ? _c('span', [_vm._v(" Open ")]) : props.row.ticket_status_id == 2 ? _c('span', {
          staticStyle: {
            "background": "#57c137"
          }
        }, [_vm._v(" Close ")]) : _c('span', {
          staticStyle: {
            "background": "#e2133f"
          }
        }, [_vm._v(" Reject ")])]) : props.column.field == 'reported_date' ? _c('span', [props.row.reported_date != 'None' ? _c('span', {
          staticStyle: {
            "background": "#57c137"
          }
        }, [_vm._v(" " + _vm._s(props.row.reported_date) + " ")]) : _c('span', [_vm._v(" " + _vm._s(props.row.reported_date) + " ")])]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "m-input-icon__icon m-input-icon__icon--left"
  }, [_c('span', [_c('i', {
    staticClass: "la la-search"
  })])]);
}]

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="stillLoading">
      <vue-simple-spinner size="large" files="Loading..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="m-subheader">
        <div class="d-flex align-items-center">
          <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">
              List {{ $route.meta.label }}
              <b style="color: blue">{{ modeForm }}</b>
            </h3>
            <p class="m-nav m-nav--inline"></p>
            <!--ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <a tabindex="0" href="#" class="" role="button" data-toggle="m-popover" data-trigger="hover" data-content="And here's some amazing content. It's very engaging. Right?">
                                <i class="m-nav__link-icon flaticon-questions-circular-button"></i>
                            </a>
                        </li>
                    </ul-->
          </div>
          <div class="d-flex align-items-right">
            <button v-if="this.modeForm == 'Verify'" @click="openDisplay" class="btn m-btn--pill m-btn--air btn-success" style="margin: 18px; width: 85%">
              /display/ {{ this.$store.getters['customer/customer'].token_trx }}
            </button>
            <!--button v-else @click="addData" class="btn m-btn--pill m-btn--air btn-success" style="margin: 18px; width: 85%;">
                      + {{$route.meta.label}}
                  </button-->
          </div>
        </div>
      </div>
      <!-- END: Subheader -->
      <div class="m-content">
        <div class="m-portlet m-portlet--mobile">
          <div class="m-portlet__body">
            <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
              <div class="row align-items-left">
                <div class="col-xl-12 order-2 order-xl-1">
                  <div class="form-group m-form__group row align-items-center">
                    <div class="col-md-4" v-for="item in filters" v-bind:key="item.id">
                      <div v-if="item.field == 'type'" class="m-input-icon m-input-icon--left">
                        <select v-model="options[item.field]" @change="onSearch" class="form-control m-input" :placeholder="item.label">
                          <option value="" selected="selected">Tipe</option>
                          <option value="wa" selected="selected">Whatsapp</option>
                          <option value="email" selected="selected">Email</option>
                        </select>
                      </div>
                      <div v-else-if="item.field != 'btn'" class="m-input-icon m-input-icon--left">
                        <input @change="onSearch" v-model="options[item.field]" class="form-control m-input" :placeholder="item.label" />
                        <span class="m-input-icon__icon m-input-icon__icon--left">
                          <span>
                            <i class="la la-search"></i>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <vue-good-table
              mode="remote"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: perPage,
                position: 'bottom',
                perPageDropdown: [5, 10],
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All'
              }"
              :totalRows="totalRecords"
              :rows="data"
              :columns="columns"
              :sort-options="{
                enabled: false
              }"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
              :row-style-class="rowStyleClassFn"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'btn'">
                  <button v-if="($store.getters['customer/customer'].role_id = 1)" class="btn btn-danger" type="button" @click="deleteMaster(props.row.id)">Delete</button>
                </div>
                <span v-else-if="props.column.field == 'hp'">
                  <a :href="linkWA(props.row.hp)" target="_blank"> {{ props.row.hp }}</a>
                </span>
                <span v-else-if="props.column.field == 'name'">
                  <a :href="props.row.name" target="_blank"> {{ props.row.name }}</a>
                </span>
                <span v-else-if="props.column.field == 'ticket_status_id'">
                  <span v-if="props.row.ticket_status_id == 1"> Open </span>
                  <span v-else-if="props.row.ticket_status_id == 2" style="background: #57c137"> Close </span>
                  <span v-else style="background: #e2133f"> Reject </span>
                </span>
                <span v-else-if="props.column.field == 'reported_date'">
                  <span v-if="props.row.reported_date != 'None'" style="background: #57c137">
                    {{ props.row.reported_date }}
                  </span>
                  <span v-else> {{ props.row.reported_date }} </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      searchTerm: null,
      options: {
        limit: 11,
        page: 1,
        sort: '',
        name: ''
      },
      //columns: [],
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: ''
    }
  },
  methods: {
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          name: null,
          sort: ''
        },
        ...this.options
      })
      this.$store
        .dispatch('files/GET_LIST_FILES', {
          masterType: this.$route.meta.name,
          params: paramsTemp
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
    },
    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        alert('Password invalid')
      }
    },
    preview(masterId) {
      this.$router.push('/files/' + masterId)
    },
    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage
      let paramsTemp = queryString.stringify({
        ...{
          limit: params.currentPerPage,
          page: params.currentPage,
          sort: ''
        },
        ...this.options
      })
      this.$store.dispatch('files/GET_LIST_FILES', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        testingName: this.$route.meta.testingName
      })
    },

    onSearch(event) {
      this.options.page = 1
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          name: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })
      this.$store.dispatch('files/GET_LIST_FILES', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        testingName: this.$route.meta.testingName
      })
    },
    editMaster(masterId) {
      this.$router.push('/files/edit/' + masterId)
    },
    deleteMaster(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          name: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('files/DELETE_FILES', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                this.$swal(resp.data.files)
                this.$store.dispatch('files/GET_LIST_FILES', {
                  masterType: this.$route.meta.name,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },
    updateStatusComplain(masterId, tipe) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          name: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('files/' + tipe, {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                this.$swal(resp.data.files)
                this.$store.dispatch('files/GET_LIST_FILES', {
                  masterType: this.$route.meta.name,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },
    addData() {
      this.$router.push('/' + this.$route.meta.name + '-Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    },
    linkWA: function (hp) {
      for (let i = 4; i > 0; i--) hp = hp.replace('-', '')
      if (hp.length > 0) {
        if (hp.substr(0, 1) == '0') hp = '+62' + hp.substr(1, hp.length - 1)
        else if (hp.substr(0, 1) == '8') hp = '+62' + hp.substr(0, hp.length)
        else if (hp.substr(0, 1) == '6') hp = '+' + hp.substr(0, hp.length)
      }
      return 'https://web.whatsapp.com/send?phone=' + hp
    }
  },
  computed: {
    data: function () {
      return this.$store.getters['files/list_files'] ? this.$store.getters['files/list_files'] : []
    },
    totalRecords: function () {
      return this.$store.getters['files/paginate'].total ? this.$store.getters['files/paginate'].total : 0
    },
    columns: function () {
      return [
        { field: 'id', label: 'Id' },
        { field: 'name', label: 'Url' },
        { field: 'created_date', label: 'Created Date' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    filters: function () {
      return [{ field: 'name', label: 'Url' }]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    }
  },
  created: function () {
    this.initData()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
<style scoped>
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
. default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
</style>
